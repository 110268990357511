import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import facepaint from 'facepaint';
import { GlobalCmsContent } from 'src/content/global-content';
import { WebsiteLink } from 'src/components/common/website-link';
import {
  flex,
  shadow,
  STACKED_STICKY_ITEM_HEIGHT_REDUCTION,
  zIndexes,
} from 'src/styling/constants';
import { SiteContext } from 'src/context/site-context';
import { ButtonStyle, RedesignButtonStyle } from 'src/enums/button-style';
import { getNewButtonCss } from 'src/components/facilities/buttonCss';
import LinkButton from 'src/components/facilities/link-button';
import { Styling } from 'src/context/styling';
import { CSSInterpolation } from '@emotion/serialize';
import HeaderDesktopMenu from 'src/components/layout/redesign/header-desktop/header-menu';
import LanguageSelection from 'src/components/layout/redesign/header-shared/language-selection';
import { breakpoints, queryForPoint } from 'src/styling/media-queries';
import DavidLloydLogo from 'src/components/icons/david-lloyd-logo';
import { getBackgroundColorByTheme } from 'src/helpers/styling';
import { BackgroundTheme } from 'src/enums/background-theme';
import { isGlobalLinkCurrentPage } from 'src/helpers/navigation-helper';
import PromoRibbon from 'src/components/layout/redesign/header-shared/promo-ribbon';
import ClubSearch from './club-search';
import { CLUBS_LINK } from 'src/enums/clubs';
import HeaderUnderlay from 'src/components/layout/redesign/header-desktop/header-underlay';
import { amplitudeTrackEvent } from 'src/helpers/amplitude-event-track';
import capitalize from 'lodash/capitalize';
import { REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE } from 'src/components/common/breadcrumbs';
import { isOnClubsPage } from 'src/helpers/path-matching-helper';
import { WindowLocation } from '@gatsbyjs/reach-router';

export const mqHeaderDisplay = facepaint([queryForPoint('64rem')]);

const mqDesktopHeader = facepaint([
  queryForPoint('80rem'),
  queryForPoint(breakpoints.maxLargeDesktopScreen),
]);

export const REDESIGN_HEADER_HEIGHT_CSS_VARIABLE = 'redesign-header-height';
export const currentHeaderHeightPx = () => {
  const heightValue = document.documentElement.style.getPropertyValue(
    `--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}`
  );

  return heightValue ? Number(heightValue.split('px')[0]) : 0;
};

// We add an extra pixel padding above the header to handle cases where the browser renderer causes an extra
// pixel spacing above the header to appear
export const ADDITIONAL_TOP_PADDING_PX = 1;

const createComponentStyling = (styling: Styling, isClubsPage?: boolean) =>
  ({
    container: [
      mqHeaderDisplay({
        'backgroundColor': getBackgroundColorByTheme(BackgroundTheme.LIGHT),
        'width': '100%',
        'display': ['none', 'flex'],
        'flexDirection': 'column',
        'alignItems': 'center',
        'position': 'sticky',
        'top': `-${ADDITIONAL_TOP_PADDING_PX}px`,
        'paddingTop': `${ADDITIONAL_TOP_PADDING_PX}px`,
        'marginTop': `-${ADDITIONAL_TOP_PADDING_PX}px`,
        'zIndex': zIndexes.header,
        '& ul': {
          margin: 0,
          padding: 0,
        },
      }),
      isClubsPage ? shadow.below : null,
    ],
    header: mqDesktopHeader({
      maxWidth: '92.25rem',
      width: '100%',
      padding: ['1rem 1.25rem', '1rem 1.25rem', '1rem 3.75rem'],
      color: styling.colors.capeCod,
    }),
    logo: {
      alignSelf: 'center',
      marginRight: '1.5rem',
    },
    navigationContainer: mqDesktopHeader({
      gap: '0.75rem',
      flexGrow: 1,
    }),
    primaryNavContainer: mqDesktopHeader({
      padding: ['0 1rem 0 0', '0 1rem 0 0', '0 1rem'],
      marginLeft: ['-0.75rem', '-0.75rem', '0'],
      flexBasis: 'max-content',
      flexGrow: 0,
      alignItems: 'center',
    }),
    primaryNavLink: {
      margin: '0.75rem',
      fontSize: '1.1875rem',
      lineHeight: '145%',
      textAlign: 'center',
      borderBottom: '1px solid',
      borderBottomColor: 'transparent',
    },
    secondaryNavContainer: {
      gap: '0.5rem',
      alignSelf: 'center',
      marginLeft: 'auto',
    },
    secondaryNavLink: [
      styling.fonts.regular,
      {
        margin: '0.5rem',
        fontSize: '0.875rem',
        lineHeight: '160%',
        borderBottom: '1px solid',
        borderBottomColor: 'transparent',
      },
    ],
    hover: {
      '&:hover': {
        color: styling.colors.camelot,
        borderBottomColor: styling.colors.camelot,
      },
    },
    selected: {
      color: styling.colors.camelot,
      borderBottomColor: styling.colors.camelot,
    },
    active: {
      borderBottomColor: styling.colors.capeCod,
    },
    actions: [
      flex.rowCentered,
      mqDesktopHeader({
        gap: '0.75rem',
        paddingTop: ['0.5rem', '0'],
        flexDirection: ['column', 'row'],
        flexWrap: 'wrap',
        flexGrow: 1,
        justifyContent: ['flex-start', 'flex-end'],
      }),
    ],
    inlineActionsWrapper: mqDesktopHeader({
      display: ['none', 'flex'],
      flexGrow: 1,
      justifyContent: 'flex-end',
      flexBasis: 'min-content',
    }),
    endActionsWrapper: mqDesktopHeader({
      display: ['flex', 'none'],
      marginLeft: '0.75rem',
    }),
    findClubButton: {
      'textWrap': 'nowrap',
      'border': `1px solid ${styling.colors.grayOlive}`,
      '&:focus': {
        border: `1px solid ${styling.colors.grayOlive}`,
      },
      'backgroundColor': 'unset',
    },
  }) satisfies Record<string, CSSInterpolation>;

type HeaderActionsProps = {
  isClubSearchVisible: boolean;
  setIsClubSearchVisible: (isVisible: boolean) => void;
  closeAllSubMenus: () => void;
  onFinish?: () => void;
};

const HeaderActions = ({
  isClubSearchVisible,
  setIsClubSearchVisible,
  closeAllSubMenus,
  onFinish,
}: HeaderActionsProps) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, pageLevel } = useContext(SiteContext);

  const componentStyling = createComponentStyling(styling);

  const handleClickHeaderButton = () => {
    onFinish?.();
    return amplitudeTrackEvent('Click to Join Club', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });
  };

  const handleClickSearchSettings = () =>
    amplitudeTrackEvent('Click to "Find a club" - Navigation', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });

  return (
    <div css={componentStyling.actions} onPointerOver={closeAllSubMenus}>
      {!!headerContent.searchSettings && (
        <button
          type="button"
          css={[
            getNewButtonCss(RedesignButtonStyle.WHITE_WITH_GREEN_FONT, styling),
            componentStyling.findClubButton,
          ]}
          onClick={() => {
            setIsClubSearchVisible(!isClubSearchVisible);
            handleClickSearchSettings();
          }}
        >
          {headerContent.searchSettings.openSearchButtonName}
        </button>
      )}

      {headerContent?.headerButton?.showButton && (
        <LinkButton
          {...headerContent.headerButton}
          buttonStyle={headerContent.headerButton.buttonStyle as ButtonStyle}
          buttonType="grid-button"
          gtmTrackingId="header-button"
          customButtonStyle={[
            getNewButtonCss(headerContent.headerButton.buttonStyle as RedesignButtonStyle, styling),
            { textWrap: 'nowrap' },
          ]}
          iconView={headerContent?.headerButton.buttonIcon}
          handleClick={handleClickHeaderButton}
          isRedesignButton
        />
      )}
    </div>
  );
};

const HeaderDesktop = ({ location }: { location: WindowLocation }) => {
  const { headerContent } = useContext(GlobalCmsContent);
  const { styling, language, buildType, basePath, pageLevel } = useContext(SiteContext);
  const isClubsPage = isOnClubsPage(location);

  const [displayedSubMenuIndex, setDisplayedSubMenuIndex] = useState<number | null>(null);
  const [isUserTabbing, setIsUserTabbing] = useState<boolean>(false);
  const [isClubSearchVisible, setIsClubSearchVisible] = useState<boolean>(false);
  const [canOpenSubMenu, setCanOpenSubMenu] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const componentStyling = createComponentStyling(styling, isClubsPage);

  const onFinishMenuAction = () => {
    closeAllSubMenus();
    setIsClubSearchVisible(false);
  };

  const closeAllSubMenus = () => {
    setDisplayedSubMenuIndex(null);
  };

  const openSubMenu = (index: number) => {
    setDisplayedSubMenuIndex(index);
    setIsClubSearchVisible(false);
  };

  const onPrimaryNavHover = (event: React.PointerEvent<HTMLLIElement>, index: number) => {
    if (!canOpenSubMenu) {
      return;
    }

    openSubMenu(index);
    event.stopPropagation();
  };

  const onPrimaryNavClick = (link: string) => {
    setIsUserTabbing(false);

    if (!isGlobalLinkCurrentPage(link, language, buildType, basePath)) {
      setCanOpenSubMenu(false);
      closeAllSubMenus();
    }
  };

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return;
    }

    document.documentElement.style.setProperty(
      'scroll-padding-top',
      `calc(var(--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}) + var(--${REDESIGN_BREADCRUMBS_HEIGHT_CSS_VARIABLE}, 0px))`
    );

    const updateHeaderHeightVariable = () => {
      const doc = document.documentElement;
      const containerHeight = containerRef.current?.getBoundingClientRect().height;

      if (!containerHeight) {
        return;
      }
      doc.style.setProperty(
        `--${REDESIGN_HEADER_HEIGHT_CSS_VARIABLE}`,
        `${containerHeight - ADDITIONAL_TOP_PADDING_PX - STACKED_STICKY_ITEM_HEIGHT_REDUCTION}px`
      );
    };

    const resizeObserver = new ResizeObserver(() => {
      requestAnimationFrame(updateHeaderHeightVariable);
    });

    resizeObserver.observe(containerRef.current);
    updateHeaderHeightVariable();

    return () => {
      document.documentElement.style.removeProperty('scroll-padding-top');
      resizeObserver.disconnect();
    };
  }, []);

  if (!headerContent) {
    return null;
  }

  const logoAmplitudeAction = () => {
    amplitudeTrackEvent('Click to Logo', {
      custom_page: window.location.origin,
      custom_level: pageLevel,
    });
  };

  const logoClick = () => {
    onFinishMenuAction();
    document.body.scrollIntoView({ behavior: 'smooth' });
    logoAmplitudeAction();
  };

  return (
    <header
      ref={containerRef}
      css={componentStyling.container}
      onPointerLeave={closeAllSubMenus}
      onPointerOver={() => setCanOpenSubMenu(true)}
    >
      <PromoRibbon />
      <div css={[componentStyling.header, flex.row]}>
        <div
          css={[
            componentStyling.logo,
            mqDesktopHeader({
              display: ['none', 'none', 'initial'],
            }),
          ]}
          onPointerOver={closeAllSubMenus}
        >
          <WebsiteLink to="/" handleClick={logoClick}>
            <DavidLloydLogo size="12.5rem" />
          </WebsiteLink>
        </div>
        <div css={[componentStyling.navigationContainer, flex.column]}>
          <div css={flex.row}>
            <div
              css={[
                componentStyling.logo,
                mqDesktopHeader({
                  display: ['initial', 'initial', 'none'],
                }),
              ]}
              onPointerOver={closeAllSubMenus}
            >
              <WebsiteLink to="/" handleClick={logoClick}>
                <DavidLloydLogo size="9.675rem" />
              </WebsiteLink>
            </div>
            <nav css={componentStyling.secondaryNavContainer}>
              <ul css={flex.row}>
                {headerContent?.headerSecondaryUpperMenu.map(({ text, link, openInNewTab }) => {
                  const handleClick = () => {
                    onFinishMenuAction();
                    return amplitudeTrackEvent(`Click to ${capitalize(text || '')} - Navigation`, {
                      custom_page: window.location.origin,
                      custom_level: pageLevel,
                    });
                  };

                  return (
                    <li key={text}>
                      <WebsiteLink
                        to={link}
                        openInNewTab={openInNewTab}
                        goToGlobal
                        handleClick={handleClick}
                      >
                        <div
                          css={[
                            componentStyling.secondaryNavLink,
                            isGlobalLinkCurrentPage(link, language, buildType, basePath)
                              ? componentStyling.active
                              : null,
                            componentStyling.hover,
                          ]}
                          onPointerOver={closeAllSubMenus}
                        >
                          {text}
                        </div>
                      </WebsiteLink>
                    </li>
                  );
                })}
                <LanguageSelection text={headerContent?.headerOtherText?.selectLanguageText} />
              </ul>
            </nav>
          </div>
          <div css={flex.row}>
            <nav
              css={[componentStyling.primaryNavContainer, flex.rowCentered]}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget) && isUserTabbing) {
                  closeAllSubMenus();
                }
              }}
            >
              <ul css={flex.row}>
                {headerContent?.headerMenuItems.map((item, index) => {
                  const handleClick = () => {
                    onFinishMenuAction();
                    return amplitudeTrackEvent(
                      `Click to ${capitalize(item.name || '')} - Navigation`,
                      {
                        custom_page: window.location.origin,
                        custom_level: pageLevel,
                      }
                    );
                  };

                  return (
                    <li key={item.name} onPointerOver={(event) => onPrimaryNavHover(event, index)}>
                      <WebsiteLink
                        to={item.link}
                        css={flex.rowCentered}
                        goToGlobal
                        onFocus={() => {
                          openSubMenu(index);
                          setIsUserTabbing(true);
                        }}
                        handleClick={handleClick}
                      >
                        <div
                          css={[
                            componentStyling.primaryNavLink,
                            styling.fonts.regular,
                            isGlobalLinkCurrentPage(item.link, language, buildType, basePath)
                              ? componentStyling.active
                              : null,
                            displayedSubMenuIndex === index ? componentStyling.selected : null,
                          ]}
                          onClick={() => onPrimaryNavClick(item.link)}
                        >
                          {item.name}
                        </div>
                      </WebsiteLink>
                      <HeaderDesktopMenu
                        {...item}
                        overviewText={headerContent.headerOtherText?.overviewText ?? ''}
                        visible={displayedSubMenuIndex === index}
                        onFinish={onFinishMenuAction}
                      />
                    </li>
                  );
                })}
              </ul>
            </nav>
            <div css={componentStyling.inlineActionsWrapper}>
              <HeaderActions
                isClubSearchVisible={isClubSearchVisible}
                setIsClubSearchVisible={setIsClubSearchVisible}
                closeAllSubMenus={closeAllSubMenus}
                onFinish={onFinishMenuAction}
              />
            </div>
          </div>
        </div>
        <div css={componentStyling.endActionsWrapper}>
          <HeaderActions
            isClubSearchVisible={isClubSearchVisible}
            setIsClubSearchVisible={setIsClubSearchVisible}
            closeAllSubMenus={closeAllSubMenus}
            onFinish={onFinishMenuAction}
          />
        </div>
        {!!headerContent.searchSettings && (
          <HeaderDesktopMenu
            description={headerContent.searchSettings.description}
            name={headerContent.searchSettings.title}
            visible={isClubSearchVisible}
            levelTwoMenuItems={[]}
            link={CLUBS_LINK}
            overviewText={headerContent.searchSettings.linkToMapText}
            enableOverflow
            onFinish={onFinishMenuAction}
          >
            <ClubSearch onFinish={onFinishMenuAction} />
          </HeaderDesktopMenu>
        )}
        {isClubSearchVisible && (
          <HeaderUnderlay
            tabIndex={0}
            onClick={() => setIsClubSearchVisible(false)}
            onFocus={() => setIsClubSearchVisible(false)}
          />
        )}
      </div>
    </header>
  );
};

export default HeaderDesktop;
